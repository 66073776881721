<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              type="search" 
              name="plantCd" 
              v-model="searchParam.plantCd" 
              @datachange="setMonths"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
            <c-datepicker
              required
              label="년월"
              :range="true"
              type="month"
              v-model="period"
              @datachange="setMonths"
            />
          </div>
        </template>
      </c-search-box>
      <div class="col-12">
        <apexchart 
          ref="chart" 
          height="700" 
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
      <!-- <div class="col-12">
        <c-table
          ref="table"
          title="통계조사표"
          tableId="table"
          :merge="grid.merge"
          gridHeight="360px"
          :columns="grid.columns"
          :data="grid.data"
        >
        </c-table>
      </div> -->
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'work-order-publish-graph',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        months: [],
      },
      chart: {
        chartOptions: {
          title: {
            text: '정비 오더 현황'
          },
          chart: {
            height: 700,
            type: 'bar',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [5, 5, 5],
            curve: 'straight',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '오더발행',
            data: []
          },
          {
            name: '작업완료',
            data: []
          },
        ],
        chartWidth: '80%',
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // 년도 설정
      this.listUrl = selectConfig.sts.rpa.workorder.url;

      this.period = [this.$comm.getPrevMonth(-6), this.$comm.getThisMonth()]

      this.setMonths();
    },
    setMonths() {
      let startYear = Number(this.period[0].substring(0, 4));
      let startMonth = Number(this.period[0].substring(5));

      let lastYear = Number(this.period[1].substring(0, 4));
      let lastMonth = Number(this.period[1].substring(5));

      this.searchParam.months = [];
      if (startYear === lastYear) {
        for (let month=startMonth; month<=lastMonth; month++) {
            let monthStr
            if (month < 10) {
              monthStr = '0' + month
            } else {
              monthStr = month
            }
          this.searchParam.months.push(startYear +'-'+ monthStr)
        }
      } else {
        for (let year=startYear; year<=lastYear; year++) {
          
          if (year===startYear) {
            for (let month=startMonth; month<=12; month++) {
              let monthStr
              if (month < 10) {
                monthStr = '0' + month
              } else {
                monthStr = month
              }
              this.searchParam.months.push(year+'-'+monthStr);
            }
          } else if (year===lastYear) {
            for (let month=1; month<=lastMonth; month++) {
              let monthStr
              if (month < 10) {
                monthStr = '0' + month
              } else {
                monthStr = month
              }
              this.searchParam.months.push(year+'-'+monthStr);
            }
          } else {
            for (let month=1; month<=12; month++) {
              let monthStr
              if (month < 10) {
                monthStr = '0' + month
              } else {
                monthStr = month
              }
             
              this.searchParam.months.push(year+'-'+monthStr);
            }
          }
        }
      }
      this.getList();
    },
    getList() {
      if (!this.period || this.period.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '해당년월을 선택하세요.', // 해당년월을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        console.log(_result.data)
        this.chart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'plan_start_dt');
        this.$set(this.chart.series[0], 'data', this.$_.map(_result.data, 'plan_count'))
        this.$set(this.chart.series[1], 'data', this.$_.map(_result.data, 'complete_count'))
        
        this.$refs['chart'].refresh();
        this.chart.chartWidth = '90%';
      },);
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>